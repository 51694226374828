import React, { useEffect } from 'react';

import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/router';

import TransText from 'next-translate/TransText';

import Trans from '@components/Trans';
import Underline from '@components/ui/Underline';
import { CTAStartFreeCreateAccount } from '@components/ui/design2023/CTAStartFreeCreateAccount';

import useTranslation from '@hooks/useTranslation';

import styles from './StickyBanner.module.css';
import { ReactComponent as DashedSVG } from './dashed.svg';

const FortyPercent = dynamic(() => import('./FortyPercent'), {
    ssr: true,
});
const MoveLogo = dynamic(() => import('./MoveLogo'), {
    ssr: true,
});
const MoveLogoPL = dynamic(() => import('./MoveLogoPL'), {
    ssr: true,
});

declare global {
    interface Window {
        dataLayer: any;
    }
}

export default function StickyBanner() {
    const { t } = useTranslation('base/_blocks/banner_move24_promo');
    const { locale } = useRouter();

    useEffect(() => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ event: 'GR_move_promo_banner_viewed', placement: 'Homepage header' });
    }, []);

    const handleClick = () => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ event: 'GR_move_24_promo_banner_clicked', placement: 'Homepage header' });
    };
    return (
        <div className={styles.banner}>
            <div className={styles.container}>
                <div className={styles.image}>
                    {locale && locale === 'pl' ? <MoveLogoPL className={styles.logo} /> : locale && ['de', 'it'].includes(locale) ? <FortyPercent className={styles.fortypercent} /> : <MoveLogo className={styles.logo} />}
                </div>
                <div className={styles.title}>
                    <Trans
                        i18nKey="title"
                        ns="base/_blocks/banner_move24_promo"
                    />
                </div>
                <div className={styles.header}>
                    <TransText
                        text={t('header').replaceAll(' class="gr-u"', '')}
                        components={{
                            u: <Underline />,
                            span: <span />,
                        }}
                    />
                </div>
                <div className={styles.buttonWrap}>
                    <CTAStartFreeCreateAccount
                        size={'small'}
                        label={t('button')}
                        color="blue"
                        params={{ version: 'centered' }}
                        className={styles.button}
                        onClick={handleClick}
                        arrow={false}
                    />
                </div>
                <DashedSVG className={styles.dashed} />
            </div>
        </div>
    );
}
